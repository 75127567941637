html{
  --color-background: #dadde3;
  --color-text: #000000;

  --color-1: #01304b;
  --color-2: #454f55;
  --color-3: #7a8392;
  --color-4: #c9d0dd;
  --color-5: #ffffff;

  --text-color-1: white;
  --text-color-2: white;
  --text-color-3: #011522;



  /*--button-background-color-clear-snippets: #8d1010;*/
  --button-background-color-clear-snippets: none;
  --button-background-color-add-snippets: #13b004;
  --button-text-color-white: white;
  --button-text-color: var(--color-text);

  --snippet-box-shadow-color: rgba(0,0,0,0.3);
  --tooltip-background-color: rgba(0,0,0,0.8);
  --tooltip-text-color: white;
  --tooltip-arrow-size: 8px;
  

  background: var(--color-background);
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





/*
  General Element Styles
*/

button{
  border: 0;
  border-radius: 0.15em;
  cursor: pointer;
  padding: 5px 10px;
}

input{
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid var(--color-4);
  outline: none;
  box-sizing: border-box;
}

input:focus{
  border-bottom: 2px solid var(--color-3);
}


.tooltip{
  position: absolute;
}

.tooltip .content{
  background: var(--tooltip-background-color);
  color: var(--tooltip-text-color);
  border-radius: 0.2em;
}

.tooltip .row-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tooltip .pointer{
  border: solid var(--tooltip-arrow-size) transparent;
  position: absolute;
}

.tooltip .left-arrow-wrapper .pointer{
  border-right-color: var(--tooltip-background-color);
  margin-left: calc( var(--tooltip-arrow-size) * -2 );
  margin-top: calc( var(--tooltip-arrow-size) / 2 );
}
.tooltip .right-arrow-wrapper .pointer{
  border-left-color: var(--tooltip-background-color);
  margin-top: calc( var(--tooltip-arrow-size) / 2 );
}
.tooltip .top-arrow-wrapper .pointer{
  border-bottom-color: var(--tooltip-background-color);
  margin-top: calc( var(--tooltip-arrow-size) * -2.5 );
  margin-left: calc( var(--tooltip-arrow-size) * -1 );
}
.tooltip .bottom-arrow-wrapper .pointer{
  border-top-color: var(--tooltip-background-color);
  margin-left: calc( var(--tooltip-arrow-size) * -1 );
}

.align-right{
   text-align: right;
 }

 .padding-top-50{
   padding-top: 50px !important;
 }

/*
  Component Styles
*/

.top-nav-bar{
  width: 100vw;
  height: 40px;
  background: var(--color-1);
  color: var(--text-color-1);
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding: 5px 30px;
}

.top-nav-bar .title-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top-nav-bar .title-div span{
  font-size: 1.2em;
}

.footer{
  width: 100vw;
  height: 0px;
  background: var(--color-2);
  color: var(--text-color-2);
  /*padding: 30px 180px;*/
}

.component-page{
  display: block;
}

.main-page .component-page-wrapper{
  display: flex;
  flex-direction: column;
  padding: 15px 180px;
}

.about-section{
  width: 100vw;
  margin-top: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: var(--color-3);
  color: var(--text-color-3);
}

.about-content-div{
  padding: 15px 60px;
  text-align: justify;
}

.about-section .title,
.about-section .content{
  padding-top: 10px;
}

.about-section .title{
  font-size: 1.25em;
  font-weight: 500;
}





.clear-snippet-div{
  display: flex;
  flex-direction: row;
  justify-content: right;
}
.clear-snippet-button{
  background: var(--button-background-color-clear-snippets);
  color: var(--button-text-color);
  text-decoration: underline;
}
.clear-snippet-button span{
  margin-left: 5px;
}

.main-page .add-snippet-div{
  margin: 25px 0 0 0;
}

.add-snippet-form{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.add-snippet-form .add-snippet-form-content-wrapper{
  padding: 0 0px;
  background: var(--color-5);
  border: 0.15em;
}

.add-snippet-input{
  width: 300px;
  margin-right: 5px;
  padding: 5px;
  border: none;
}

.add-snippet-input:focus{
  border: none;
}

.add-snippet-button{
  background: var(--button-background-color-add-snippets);
  color: var(--button-text-color-white);
}


.snippet-list{
  margin-top: 20px;
  background: var(--color-4);
  border-radius: 0.25em;
  padding: 10px;
  min-height: 50vh;
}

.snippet-item{
  background: white;
  padding: 5px;
  border-radius: 0.15em;
  margin: 5px;
  box-shadow: 1px 1px var(--snippet-box-shadow-color);
  max-width: 450px;
}

.snippet-item-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.snippet-item-input{
  margin: 0 5px;
  width: 70%;
  min-width: 200px;
}

.snippet-item-delete-button{
  background: none;
  color: var(--color-1);
  opacity: 0.3;
}

.snippet-item-delete-button:hover{
  opacity: 0.7;
}


.snippet-item .tooltip{
  opacity: 0;
  padding-left: 15px;
}

.snippet-item .tooltip .content{
  margin-top: -4px;
  padding: 6px;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
}

.snippet-item .snippet-item-drag-indicator{
  padding-left: 10px;
  color: var(--color-1);
  opacity: 0;
}

.snippet-item:hover .snippet-item-drag-indicator,
.snippet-item:active .snippet-item-drag-indicator{
  opacity: 0.3;
}

.snippet-list .snippet-list-empty-tips{
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.snippet-list .snippet-list-empty-tips .snippet-list-empty-tips-column{
  padding: 10px;
  font-size: 0.9em;
  opacity: 0.7;
}

.snippet-list .snippet-list-empty-tips .snippet-list-empty-tips-column .icon{
  font-size: 0.8em;
}




@media (max-width: 720px){
  .component-page{
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}


@media (max-width: 360px){
  .add-snippet-input{
    width: 150px;
  }

  .snippet-item-input{
    width: 70px;
    min-width: 70px;
  }
}


@media (prefers-reduced-motion: no-preference) {
  .tooltip {
    animation: Tooltip-fadeout 1 2s linear;
  }
}




@keyframes Tooltip-fadeout {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}